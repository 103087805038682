












































































































































































































































import {
    Component,
    Vue
} from 'vue-property-decorator';
 
import _ from 'lodash'
import {
    Auth
} from '@/store/auth'
import {
    Core
} from '@/store/core'
import {
    User
} from '@/store/user'

@Component({
    components: {
         
    },
    props: {
        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    }
})
export default class Home extends Vue {

    private user: any = {}
    private year: any = []
    private rates: any = []
    private dialog: boolean = false
    private rate: any = {}
    private result: any = null
    private form: any = {}
    private formUpdate: any = null

    private urlList: any = null
    private urlResponse: boolean = true
    private url: any = []
    private urlForm: any = {}

    private rateDatas: any = []
    private response: boolean = false
    private resultResponse: boolean = false

    public async created() {

        await this.run()

    }
    rateStatus: any = []
    private async run() {
        let loader = await this.$loading.show()
        this.response = false;
        this.user = await User.getUser();
        this.year = await Core.getHttp(`/api/ita/v2/year/${this.$route.query.year}/`)
        this.rates = await Core.getHttp(`/api/ita/v2/rate/${this.$route.query.year}/`)
        this.result = await Core.getHttp(`/api/ita/v1/rateresult/?agency=${this.user.ext_link.agency}`)
        this.rateStatus = await Core.getHttp(`/api/ita/v1/ratestatus/`)
        await this.generateTable();
        this.response = true;
        await loader.hide()
    }

    private async generateTable() {
        for (let i = 0; i < this.rates.length; i++) {
            console.log(this.rates[i].id);
            let result: any = _.filter(this.result, {
                'rate': this.rates[i].id
            })
            this.rates[i].result = result
        }
    }

    public async openDialog(rate: any) {

        await this.loadRateData(rate);
        this.dialog = true;

    }
    private async loadRateData(rate: any) {
        this.rate = await Core.getHttp(`/api/ita/v1/rate/${rate.id}/`)
        this.form.rate = rate.id
        this.form.name = rate.name
        this.rateDatas = await Core.getHttp(`/api/ita/v1/rateresult/?agency=${this.user.ext_link.agency}&rate=${this.rate.id}`)
        console.log(this.rate)
        this.resultResponse = true;
    }

    public async closeDialog() {
        this.rate = null
        this.form = {}
        this.dialog = false;
        this.resultResponse = false;
    }

    private async saveData() {
        this.form.user = this.user.pk
        this.form.agency = this.user.ext_link.agency
        let data = await Core.postHttp(`/api/ita/v1/rateresult/`, this.form)
        if (data.id) {
          alert('บันทึกข้อมูลสำเร็จแล้ว')
          this.form = {}
            await this.loadRateData(this.rate)
        }
        await this.run()
    }

    private async getResultByUser(i: number) {
        return await Core.putHttp(`/api/ita/v2/rateresult/`, {
            "agency": this.user.ext_link.agency,
            "rate": this.rates[i].id
        })
    }

    private async updateData(data: any) {
        let update = await Core.putHttp(`/api/ita/v1/rateresult/${data.id}/`, data)
        if (update.id) {
          alert('แก้ไขข้อมูลสำเร็จแล้ว')
            await this.loadRateData(this.rate)
        }
    }

    private async removeData(data: any) {
        let check = confirm("คุณแน่ใจใช่ไหมที่จะลบรายการนี้")
        if (check) {
            let remove = await Core.deleteHttp(`/api/ita/v1/rateresult/${data.id}/`)
        }
        this.resultResponse = false;
        await this.loadRateData(this.rate)
        this.resultResponse = true;

    }

    private async passingStore(data: any) {
        let check = confirm("คุณแน่ใจใช่ไหมที่จะส่งเนื้อหานี้ให้กับผู้ตรวจ")
        if (check) {
            let form = {
                "user_passing": this.user.pk,
                "passing": true
            }
            let store = await Core.putHttp(`/api/ita/v2/passing/${data.id}/`, form)
            if (store.id) {
                await this.loadRateData(this.rate)
            }
        }

    }

    private async removePassingStore(data: any) {
        let check = confirm("คุณแน่ใจใช่ไหมที่จะส่งเนื้อหานี้ให้กับผู้ตรวจ")
        if (check) {
            let form = {
                "user_passing": this.user.pk,
                "passing": false
            }
            let store = await Core.putHttp(`/api/ita/v2/passing/${data.id}/`, form)
            if (store.id) {
                await this.loadRateData(this.rate)
            }
        }

    }

    private openLink(url: string) {
        window.open(url, '_blank');
    }

    getPassingTest(result:any){
        let res = _.filter(result,{'rate':4})
        return res.length;
    }

    getTest(result:any){
        let res = _.filter(result,function(o) { return o.tester != null; })
        return res.length;
    }

}
